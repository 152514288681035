<script setup lang="ts">
    // @ts-ignore
    import { useI18n } from 'vue-i18n';

    import useNavigationStore from '@/stores/ui/navigation'
    import { onMounted, onUnmounted, ref } from 'vue'
    import { navigate } from 'vike/client/router'
    import { usePageContext } from 'vike-vue/usePageContext'
    import Link from '@/views/components/Link.vue'

    // Local variables
    const { t } = useI18n()

    const showButton = ref(false);

    const pageContext = usePageContext()

    // Stores
    const navigationStore = useNavigationStore()

    // Functions
    const scrollToSection = async (id: string) => {
        if (pageContext.urlPathname !== '/') {
            navigationStore.setHomeScrollTo(id)
            await navigate('/')
        } else {
            const section = document.getElementById(id)

            if (section) {
                section.scrollIntoView({
                    behavior: "smooth"
                })
            }
        }
    };

    const scrollToTop = () => {
      const section = document.getElementById("home")

      if (section) {
            setTimeout(() => {
              section.scrollIntoView({
                behavior: "smooth"
              })
            }, 500);
      }
    }

    onMounted(() => {
        if (pageContext.urlPathname === '/') {
            document.getElementById("body")?.addEventListener('scroll', handleScroll)
        } 
        
    })
    onUnmounted(() => {
      document.getElementById("body")?.removeEventListener('scroll', handleScroll)
    })

  
    function handleScroll() {
        const offset = 500; 
        const body = document.getElementById("body")
        if (body !== null) {
            if (body!.scrollTop  > offset) {
            showButton.value = true;
            } else {
            showButton.value = false;
            }
        }
    }
</script>

<template>

    <footer  class="footer-bg">
      <!-- Footer Area Start -->
      <div class="footer-Content pt-5">
        <div class="container">

          <div class="row menu">
            <!-- Colonne 1 : Logo et paragraphe -->
            <div class="col-md-4  pr-4 addWhiteBorder">
              <p class="textwidget">
                {{ t("w_txt_home_footerPlatform") }} <strong><span style="color: #2da7d4;font-size: larger;">{{ t("w_txt_home_footerTope") }}</span><span style="color: #f0a62f;font-size: larger;">{{ t("w_txt_home_footerLa") }}</span></strong> {{ t("w_txt_home_footerTopelaDescription") }}
              </p>
            </div>
            <div class="col-md-8 ">
              <div class="row pl-5 add-padding">
                <!-- Colonne 2 : Liens utiles -->
                <div class="col-md-5" >
                  <h4 class="text-uppercase">{{ t("w_txt_footer_usefulLinks") }} </h4>
                  <ul class="list-unstyled">
                    <li><Link class="footer-link" href="/general-conditions-of-use">{{ t("w_txt_home_footerTermsAndConditionsOfUse") }} </Link></li> <br/>
                    <li><Link class="footer-link" href="/general-conditions-of-sale">{{ t("w_txt_home_footerTermsAndConditionsOfSale") }} </Link></li><br/>
                    <li><Link class="footer-link" href="/privacy-policy">{{ t("w_act_footer_privacyPolicy") }} </Link></li><br/>
                    <li><Link class="footer-link" href="/cookies-policy">{{ t("w_txt_home_footerCookiesPolicy") }} </Link></li><br/>
                  </ul>
                </div>
                <div class="col-md-3 pl-0 ">
                  <h4 class="text-uppercase">{{ t("w_txt_home_footerAbout") }} </h4>
                  <ul class="list-unstyled">
                    <li><Link class="footer-link" href="/about-us">{{ t("w_txt_home_footerAboutUs") }} </Link></li><br/>
                    <li><Link class="footer-link" href="/legal-notice">{{ t("w_act_footer_legalNotice") }}</Link></li><br/>
                  </ul>
                </div>
                <!-- Colonne 3 : Liens contractuels -->
                
                <!-- Colonne 4 : Informations de contact -->
                <div class="col-md-3 pl-0">
                    <h4 class="text-uppercase">{{ t("w_txt_home_contact") }} </h4>
                    <ul class="list-unstyled">
                        <li>
                            <a class="page-scroll footer-link" href="/#contact" @click.prevent="scrollToSection('contact')">
                              {{ t("w_act_footer_contact") }}
                            </a>
                        </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Copyright Start  -->
        <div class="copyright d-flex justify-content-between align-items-center">
            <div class="container">
                <div class="text-center">
                    <p v-html="t('w_txt_footer_copyright')"></p>
                </div>
            </div>
            <a v-show="showButton" href="#" class="scrol-to-top" @click.prevent="scrollToTop">
                <i class="lni-chevron-up"></i>
            </a>
        </div>
    </div>
      <!-- Footer area End -->
    </footer>
    <!-- Footer Section End -->
</template>

<style scoped>
    a {
        color: white !important;
    }

    .footer-link:hover{
        color:#FB9307 !important;
    }
    .scrol-to-top{
        position: fixed;
        bottom: 18px;
        right: 15px;
        width: 45px;
        height: 45px;
        line-height: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 4px;
        background-color: white !important;
        box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
    }

  .scrol-to-top i {
    color:#2da7d4 !important;
    background: linear-gradient(to right, #233648 0%, #233648 100%);
    -webkit-background-clip: text;
    font-size: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  @media (min-width: 992px) {
    .add-padding {
      padding-left: 3rem !important;
    }
    .addWhiteBorder{
      border-right: 1px solid #fff
    }
  }
</style>